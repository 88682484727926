
import { defineComponent, nextTick, reactive, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// import sign from '@/components/sign.vue'
// import HeaderTit from '@/components/HeaderTit.vue'
import { getStorage } from '@/commons/SessionStorageUtils'
import AxiosAdapter from '@/request/AxiosAdapter'
import { ElMessage } from 'element-plus'
// import LinkAge from '@/components/Selection.vue'
import { StringObjectType } from '@/commons/Types'
import { getRefFunc, requestFunc } from '@/service/Linkage'
import { emailReg, IDReg, mobileReg } from '@/commons/unit'
import { throwCustomFunction } from '@/commons/ErrorHandle'
import PreView from '@/components/preview/PreView'
import signature from './signature.vue'
// import { nextTick } from '../../../preservation-mpvue/static/vant/common/utils'

export default defineComponent({
  name: 'ConfirmAddressNormal',
  components: { signature },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const signature = ref(null)
    const agentSignature = ref(null)
    const areaName = ref('')
    const isHavingImg = ref(false)
    const confirm = ref(true)
    const signSrc = ref('')
    const agentSignSrc = ref('')
    const sign = ref(null)
    const signShow = ref(false)
    const receiverName = ref()
    const signImgStr = ref()
    const agentSignShow = ref(false)
    const agentSignImgStr = ref()
    const newData =
      new Date().getFullYear() +
      '年' +
      (new Date().getMonth() + 1) +
      '月' +
      new Date().getDate() +
      '日'
    let courtName = ''
    const getUrl = process.env.VUE_APP_BASE_API
    const verifyCode = ref('')
    const dialogVisible = ref(false)
    const urls = reactive({
      frontUrl: '',
      reverseUrl: ''
    })
    const areaOptions: StringObjectType<any> = reactive({
      shengList: [],
      shiList1: [],
      quList1: [],
      shiList2: [],
      quList2: []
    })

    const signs = reactive([
      {
        text: '受送达人(签章):',
        bindField: signature,
        title: 'signature',
        isHavingImg: false,
        img: ''
      }
    ])

    const agentSigns = reactive([
      {
        text: '法院工作人员签名:',
        bindField: agentSignature,
        title: 'agentSignature',
        isHavingImg: false,
        img: ''
      }
    ])
    const isElectronicList = ref([
      {
        code: true,
        name: '是'
      },
      {
        code: false,
        name: '否'
      }
    ])
    const deliveryAccountOptions = reactive([
      {
        id: 2,
        msg: '微信',
        msg2: '我的微信号是:',
        warn: '请输入微信号',
        deliveryAccount: '',
        disabled: false
      },
      {
        id: 3,
        msg: 'QQ',
        msg2: '我的QQ号是:',
        warn: '请输入QQ号',
        deliveryAccount: '',
        disabled: false
      },
      {
        id: 4,
        msg: '电子邮件',
        msg2: '我的电子邮箱号是:',
        warn: '请输入电子邮件',
        deliveryAccount: '',
        disabled: false
      },
      {
        id: 5,
        msg: '传真',
        msg2: '我的传真号是:',
        warn: '请输入传真号',
        deliveryAccount: '',
        disabled: false
      }
    ])
    const electronicTypeOption = ref()
    const userIdentityTypeList = ref([
      {
        code: 1,
        name: '居民身份证'
      },
      {
        code: 2,
        name: '中国公民护照'
      },
      {
        code: 7,
        name: '统一社会信用代码'
      },
      {
        code: 9,
        name: '律师证'
      }
    ])

    const ruleForm: any = reactive({
      caseCause: '',
      caseNumberWord: '',
      institutionId: '',
      courtUserSignature: '',
      electronicType: [],
      id: '',
      agentCityCode: '',
      agentDetailAddress: '',
      agentDistrictCode: '',
      agentIdentityNumber: '',
      agentIdentityType: '',
      agentMobilePhone: '',
      agentName: '',
      agentProvinceCode: '',
      remark: '',
      signature: '',
      userCityCode: '',
      userDetailAddress: '',
      userDistrictCode: '',
      userIdentityNumber: '',
      userIdentityType: '',
      userMobilePhone: '',
      userName: '',
      userProvinceCode: '',
      wechatAccount: '',
      qqAccount: '',
      email: '',
      faxNumber: ''
    })

    const province = ref(0)
    // 初始化省

    const getProvinceList = async () => {
      const data = await AxiosAdapter.get({ url: '/city/list' })
      areaOptions.shengList = data
    }

    const getCityList = async (code: any, type: any) => {
      // cityOptions.values = []
      // districtOptions.values = []
      // ruleForm.cityCode = ''
      // ruleForm.districtCode = ''

      if (code) {
        const data = await AxiosAdapter.get({
          url: '/city/list',
          data: { parentCode: code }
        })
        const istype: any = 'shiList' + type
        areaOptions[istype] = data
      }
      if (type == 1) {
        areaOptions.quList1 = []
        ruleForm.userCityCode = ''
        ruleForm.userDistrictCode = ''
      }
      if (type == 2) {
        ruleForm.agentCityCode = ''
        ruleForm.agentDistrictCode = ''
        areaOptions.quList2 = []
      }
    }

    const getCityList1 = async (code: any, type: any) => {
      if (code) {
        const data = await AxiosAdapter.get({
          url: '/city/list',
          data: { parentCode: code }
        })
        const istype: any = 'shiList' + type
        areaOptions[istype] = data
      }
    }

    const getDistrictList1 = async (code: any, type: any) => {
      if (code) {
        const data = await AxiosAdapter.get({
          url: '/city/list',
          data: { parentCode: code }
        })
        const istype: any = 'quList' + type
        areaOptions[istype] = data
      }
    }
    const getDistrictList = async (code: any, type: any) => {
      if (code) {
        const data = await AxiosAdapter.get({
          url: '/city/list',
          data: { parentCode: code }
        })
        const istype: any = 'quList' + type
        areaOptions[istype] = data
      }
      if (type == 1) {
        ruleForm.userDistrictCode = ''
      }
      if (type == 2) {
        ruleForm.agentDistrictCode = ''
      }
    }

    if (route.query && typeof route.query.courtName === 'string') {
      courtName = route.query.courtName
    }

    const submit = async (ruleForm: any) => {
      if (!ruleForm.userName) {
        throwCustomFunction('请输入姓名')
      }
      if (ruleForm.userIdentityType == 1) {
        if (!IDReg.test(ruleForm.userIdentityNumber)) {
          throwCustomFunction('请输入正确的身份证号')
        }
      }
      if (ruleForm.userIdentityType != 1) {
        if (!ruleForm.userIdentityNumber) {
          throwCustomFunction('请输入身份证号')
        }
      }
      if (!mobileReg.test(ruleForm.userMobilePhone)) {
        throwCustomFunction('请输入正确的手机号')
      }

      if (!ruleForm.userProvinceCode) {
        throwCustomFunction('请选择所在地区')
      }

      if (!ruleForm.userDetailAddress) {
        throwCustomFunction('请输入详细地址')
      }

      if (!route.query.institutionId) {
        throwCustomFunction('无法获取当前法院')
      }

      ruleForm.institutionId = route.query.institutionId
      let canSubmit = false
      signs.forEach((current) => {
        if (current.isHavingImg) {
          canSubmit = true
          return
        }
        // const val: any = current.bindField
        // if (val.linen.length > 60) {
        //   canSubmit = true
        if (signImgStr.value) {
          canSubmit = true
          ruleForm[current.title] = signImgStr.value
        }
        // ruleForm[current.title] = signImgStr.value
        // }
      })
      agentSigns.forEach((current) => {
        if (current.isHavingImg) {
          canSubmit = true
          return
        }
        // const val: any = current.bindField
        // if (val.linen.length > 60) {
        //   canSubmit = true
        if (agentSignImgStr.value) {
          canSubmit = true
          ruleForm[current.title] = agentSignImgStr.value
        }
        // ruleForm[current.title] = agentSignImgStr.value
        // }
      })
      if (!canSubmit) {
        throwCustomFunction('请手写签名')
      }
      if (!confirm.value) {
        throwCustomFunction('请确认送达内容')
      }

      const getruleForm: any = JSON.parse(JSON.stringify(ruleForm))

      getruleForm.electronicType = getruleForm.electronicType.toString(',')
      // 1 短信 2 微信 3 qq 4 电子邮件 5 传真
      // getruleForm.faxNumber=deliveryAccountOptions[0].deliveryAccount
      getruleForm.wechatAccount = deliveryAccountOptions[0].deliveryAccount
      getruleForm.qqAccount = deliveryAccountOptions[1].deliveryAccount
      getruleForm.email = deliveryAccountOptions[2].deliveryAccount
      getruleForm.faxNumber = deliveryAccountOptions[3].deliveryAccount
      const data: any = await AxiosAdapter.post({
        url: '/confirmAddress/addChongZuoAddress',
        data: getruleForm
      })
      ElMessage.success({
        type: 'success',
        message: '操作成功',
        duration: 2000
      })

      // return
      PreView(data, router)
      // return
      // router.push({
      //   path: '/confirm-address-detail',
      //   query: {
      //     url: data
      //     // url: 'https://www.i12368.com/webNew/files/2020/340123/2020111823300000007/2020111823300000011/2020121523300000007/delivery/signPdf/2020111823300000007.pdf'
      //     // url: 'https://www.i12368.com/webNew/files/2020/electronic/2020111823300000007/delivery/32388e69bc624fbda32a050ecd64f877.jpeg'
      //   }
      // })
    }

    const gotoPage = () => {
      router.back()
    }
    const modifyMessage = () => {
      dialogVisible.value = true
    }
    const cancelGetCode = () => {
      dialogVisible.value = false
      verifyCode.value = ''
    }
    const verifyCodeFun = async (verifyCode?: string) => {
      // const params = {
      //   code: verifyCode,
      //   institutionId: route.query.institutionId
      // }
      AxiosAdapter.post({
        url:
          '/confirmAddress/checkRandomCode?institutionId=' +
          route.query.institutionId +
          '&code=' +
          verifyCode
      }).then(async (res) => {
        const data: any = res
        if (data.userProvinceCode) {
          await getCityList1(data.userProvinceCode, 1)
        }
        if (data.userCityCode) {
          await getDistrictList1(data.userCityCode, 1)
        }

        if (data.agentProvinceCode) {
          await getCityList1(data.agentProvinceCode, 2)
        }
        if (data.agentCityCode) {
          await getDistrictList1(data.agentCityCode, 2)
        }
        ruleForm.id = data.id
        ruleForm.caseCause = data.caseCause
        ruleForm.caseNumberWord = data.caseNumberWord
        ruleForm.institutionId = data.institutionId
        ruleForm.electronicType = data.electronicType
          ? data.electronicType.split(',')
          : []
        ruleForm.agentCityCode = data.agentCityCode
        ruleForm.agentDetailAddress = data.agentDetailAddress
        ruleForm.agentDistrictCode = data.agentDistrictCode
        ruleForm.agentIdentityNumber = data.agentIdentityNumber
        ruleForm.agentIdentityType = data.agentIdentityType
        ruleForm.agentMobilePhone = data.agentMobilePhone
        ruleForm.agentName = data.agentName
        ruleForm.agentProvinceCode = data.agentProvinceCode
        ruleForm.remark = data.remark
        ruleForm.userCityCode = data.userCityCode
        ruleForm.userDetailAddress = data.userDetailAddress
        ruleForm.userDistrictCode = data.userDistrictCode
        ruleForm.userIdentityNumber = data.userIdentityNumber
        ruleForm.userIdentityType = data.userIdentityType
        ruleForm.userMobilePhone = data.userMobilePhone
        ruleForm.userName = data.userName
        ruleForm.userProvinceCode = data.userProvinceCode
        ruleForm.wechatAccount = data.wechatAccount
        ruleForm.qqAccount = data.qqAccount
        ruleForm.qqAccount = data.qqAccount
        ruleForm.email = data.email
        ruleForm.faxNumber = data.faxNumber
        ruleForm.signature = data.signature

        deliveryAccountOptions[0].deliveryAccount = data.wechatAccount
        deliveryAccountOptions[1].deliveryAccount = data.qqAccount
        deliveryAccountOptions[2].deliveryAccount = data.email
        deliveryAccountOptions[3].deliveryAccount = data.faxNumber

        signs.forEach((item) => {
          const img = data[item.title]
          if (img) {
            item.isHavingImg = true
            item.img = getUrl + img
            signSrc.value = process.env.VUE_APP_BASE_API + data[item.title]
          }
        })
        agentSigns.forEach((item) => {
          const img = data[item.title]
          if (img) {
            item.isHavingImg = true
            item.img = getUrl + img
            agentSignSrc.value = process.env.VUE_APP_BASE_API + data[item.title]
          }
        })
        if (data.id) {
          ruleForm.signature = ''
          ruleForm.agentSignature = ''
        }
      })
    }
    const confirmGetCode = async () => {
      if (verifyCode.value) {
        await verifyCodeFun(verifyCode.value)
        cancelGetCode()
      }
    }

    const clearSignature = async (current: any) => {
      // const fieldItem = current.bindField
      const val: any = current.bindField
      val.clear()
    }

    const reset = async (current: any) => {
      current.isHavingImg = false
      const val: any = current.bindField
      nextTick(() => {
        val.clear()
      })
    }

    const signreturnEvent = () => {
      signShow.value = false
    }

    const signEvent = (e: any) => {
      const val: any = signs[0]
      val.isHavingImg = false
      signShow.value = false
      signImgStr.value = e
    }
    const agentSignreturnEvent = () => {
      agentSignShow.value = false
    }

    const agentSignEvent = (e: any) => {
      const val: any = agentSigns[0]
      val.isHavingImg = false
      agentSignShow.value = false
      agentSignImgStr.value = e
    }

    const signShowFun = () => {
      signShow.value = true
    }
    const agentSignShowFun = () => {
      agentSignShow.value = true
    }
    onMounted(() => {
      getProvinceList()
    })

    return {
      getProvinceList,
      getCityList,
      getCityList1,
      getDistrictList,
      getDistrictList1,
      deliveryAccountOptions,
      electronicTypeOption,
      submit,
      agentSigns,
      gotoPage,
      modifyMessage,
      getStorage,
      getUrl,
      urls,
      confirm,
      agentSignature,
      signature,
      signSrc,
      signShow,
      agentSignImgStr,
      courtName,
      signs,
      ruleForm,
      clearSignature,
      reset,
      dialogVisible,
      areaName,
      newData,
      userIdentityTypeList,
      isElectronicList,
      verifyCodeFun,
      agentSignShow,
      confirmGetCode,
      verifyCode,
      signShowFun,
      cancelGetCode,
      areaOptions,
      signreturnEvent,
      signEvent,
      isHavingImg,
      receiverName,
      signImgStr,
      agentSignEvent,
      agentSignShowFun,
      agentSignreturnEvent,
      agentSignSrc
    }
  }
})
